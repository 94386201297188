.section {
  background: $section-bg;
  margin: $section-margin;
  padding: 0; // Resets CSSR padding on this, @see .section-block.
  position: $section-position;
}

.section-container {
  width: 100%;
  @extend .container;
}

.section-wrapper {
  position: relative;
}

.section-block {
  background: $section-block-bg;
  position: $section-block-position;
}

.section-title {
  margin: 0;
  margin-bottom: 0;
}

.section-subtitle {
  margin-bottom: $section-spacer-y * (2/3);
  margin-top: 0;
}

.section-text {
  margin: 0;
  margin-bottom: $section-spacer-y;
}

.section-block {
  padding: $section-spacer-y $section-spacer-x;

  &.section-block-grid {
    padding-bottom: 0;
  }
}

.section-btn,
.section-element {
  margin-bottom: $section-spacer-y;
}

.section-block,
.section-header,
.section-footer {
  > *:last-child {
    margin-bottom: 0;
  }
}

.section-btn {
  @extend #{$section-btn-prototype};
}

.section-img {
  &,
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-header,
.section-footer {
  padding: 0;
}

.section-title {
  color: $brand-dark;
  font-family: $section-title-font-family;
  font-size: $section-title-font-size;
  line-height: $section-title-line-height;
  padding-bottom: 67px;
  text-align: $section-title-text-align;
}

.section-header {
  background: $section-header-bg;
}

.section-footer {
  background: $section-footer-bg;
  text-align: center;
}

@mixin background-alternate ($bg-color, $section-title-color) {
  background-color: $bg-color;

  .section-title {
    color: $section-title-color;
  }
}

.section--features {
  .section-title {
    padding-bottom: 41px;
    pointer-events: none;

    .section-title-icon {
      display: none;
    }
  }

  .list--list {
    display: flex;
    margin: 0 -11.5px;

    .list-item {
      margin-bottom: 41px;
      padding: 0 11.5px;
      width: 25%;

      .card--feature {
        .card-img {
          > span.card-img-icon {
            background-image: url("../../../../images/svgicons/asset-pdf.svg");
            height: 48px;
            left: 8px;
            position: absolute;
            top: 8px;
            width: 48px;
          }
        }
      }

      &:nth-child(2) {
        .card--feature {
          .card-img {
            > span.card-img-icon {
              background-image: url("../../../../images/svgicons/asset-image.svg");
            }
          }
        }
      }

      &:nth-child(3) {
        .card--feature {
          .card-img {
            > span.card-img-icon {
              background-image: url("../../../../images/svgicons/asset-video.svg");
            }
          }
        }
      }

      &:nth-child(4) {
        .card--feature {
          .card-img {
            > span.card-img-icon {
              background-image: url("../../../../images/svgicons/asset-folder.svg");
            }
          }
        }
      }

      .card-block {
        background-color: $brand-grey;
        flex: 1;
        padding: 27px 17px;

        .card-title {
          font-family: $font-family-bold;
          font-size: $font-size-xl;
          line-height: 24px;
          padding-bottom: 0;

          &:hover {
            color: $brand-dark;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .list--list {
      flex-direction: column;

      .list-item {
        width: 100%;
      }
    }
  }
}

.section--assets,
.section--files,
.section--folders,
.section--photos,
.section--videos {
  .gallery.row {
    margin: 0 -12px;
  }

  .gallery-item {
    &[class*="col-"] {
      padding: 0 12px;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .section--assets,
  .section--files,
  .section--folders,
  .section--photos,
  .section--videos {
    .gallery.row {
      margin: 0 -16px;
    }

    .gallery-item {
      &[class*="col-"] {
        padding: 0 16px;
      }
    }
  }
}

.layout--channel-show {
  .section--experts {
    background-color: $brand-light-gray;

    .section-btn {
      background-color: $brand-light-gray;
    }

    .gallery.row {
      margin: 0 -8px;

      .gallery-item[class*="col-"] {
        padding: 0 8px;
      }
    }
  }

  .section--news {
    margin-bottom: 0;

    .section-block-grid {
      margin-bottom: 43px;
    }

    .gallery {
      &.row {
        margin: 0 -8px;
      }

      .gallery-item {
        &[class*="col-"] {
          padding: 0 8px;
        }
      }
    }
  }

  .section.section--composite {
    background: $brand-secondary;
    padding-bottom: 36px;
    padding-top: 50px;

    .section-composite.section--main {
      background: $brand-secondary;
      float: left;
      padding-left: 96px;
      padding-right: 24px;
      width: 73%;
    }

    .section--sidebar {
      background: $brand-secondary;
      float: left;
      width: 27%;
    }
  }

  .section--features {
    padding: 50px 36px;

    .section-container {
      padding: 0 71px;
    }

    .section-title {
      color: $brand-primary;
    }
  }

  .section--clippings {
    padding: 45px 0 35px;

    .section-title {
      color: $brand-primary;
      padding-bottom: 57px;
    }

    .gallery.gallery-list {
      .gallery-item:last-child {
        margin-bottom: 44px;
      }
    }
  }

  .section--events {
    background-color: $brand-light-gray;
    padding: 48px 0 46px;

    .section-btn {
      background-color: $brand-light-gray;
    }
  }

  .layout-hook--main {
    .section-btn .section-btn-text {
      text-transform: uppercase;
    }

    .section--odd:not(.section--web_publications_highlight) {
      background-color: $brand-light-gray;

      .section-title {
        color: $brand-dark;
      }

      .card,
      .card-block {
        background: $brand-secondary;
      }

      .section-btn {
        background-color: $brand-light-gray;
      }

      &.section--clippings {
        .card,
        .card-block {
          background: $brand-light-gray;
        }
      }
    }

    .section--even {
      background-color: $brand-secondary;

      .section-title {
        color: $brand-primary;
      }

      .card,
      .card-block {
        background: $brand-light-gray;
      }

      .section-btn {
        background-color: $brand-secondary;
      }

      .section--news {
        .card,
        .card-block {
          background: $brand-secondary;
        }
      }

      &.section--clippings,
      &.section--events {
        .card,
        .card-block {
          background: $brand-secondary;
        }
      }
    }

    .section--tags {
      &.section--tags-highlight-mobile {
        background-color: $brand-primary;

        .section-title {
          color: $brand-secondary;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section.section--composite {
      padding: 32px 0;
    }

    .section--features {
      padding: 32px 0;

      .section-container {
        padding: 0 16px;
      }
    }

    .section.section--composite {
      .section-composite.section--main,
      .section--sidebar {
        padding: 0 16px;
        width: 100%;
      }
    }
  }
}

.section--spokespersons {
  background: $brand-secondary;

  .section-container {
    width: 100%;

    .section-title {
      color: $brand-primary;
    }
  }
}

.section--news.section--fluidbook {
  .section-block-grid {
    margin-bottom: 0;
  }
}

.section--fluidbook-mobile,
.section--spokespersons-mobile,
.section--webidentities-highlight-mobile,
.section--tags-highlight-mobile {
  display: none;

  @media screen and (max-width: $screen-md) {
    display: block;
    margin-top: 0;
  }
}

.section--webidentities-highlight-mobile,
.section--tags-highlight-mobile {
  .section-title {
    cursor: unset;

    .section-title-icon {
      display: none;
    }
  }
}

.layout--expert-index {
  .section--experts_highlight {
    padding-bottom: 0;
  }

  .section--experts:not(.section--experts_highlight) {
    padding-top: 0;
  }
}
